import { Optional } from '@fp-ts/optic';
import {
  TalentCriteriaItemFragment,
  TalentSortInput,
} from '../../graphql/generated';
import { ReadonlyArray } from 'effect';
import {
  TalentsListState,
  TalentsListStateColumnConfiguration,
} from './talents-list-state.types';
import {
  addPermanentFilter,
  changeFilters,
  changeFilterTabName,
  chooseDefaultActiveTab,
  filterCriteriaWithUuidOfCurrentUser,
  filterIsPinnedWithUuidOfCurrentUser,
  filterNameWithUuidOfCurrentUser,
  filterSortWithUuidOfCurrentUser,
  filterWithUuidOfCurrentUser,
  replacePermanentFilterCriteriaOfCurrentUser,
  replaceSortOfCurrentUser,
  setFilterPinnedState,
  setValidActiveTab,
  tableColumnVisibilityWithIdForTab,
  tableConfigurationColumnsOfFilterLens,
  tableConfigurationForTabId,
  updateEnabledColumnWidthsForTabId,
} from '../advanced-data-list/advanced-data-list.optics';

/**
 * An optic that zooms in to the table configuration of a talent filter.
 */
export const tableConfigurationColumnsOfTalentFilterLens: () => Optional<
  TalentsListState['tabs'][0],
  ReadonlyArray<TalentsListStateColumnConfiguration>
> = tableConfigurationColumnsOfFilterLens<TalentsListState>;

/**
 * This function returns an optic that zooms into a talent filter with a given uuid
 * @param uuid  Talent filter uuid
 */
export const talentFilterWithUuidOfCurrentUser: (
  uuid: string,
) => Optional<TalentsListState, TalentsListState['tabs'][0]> =
  filterWithUuidOfCurrentUser<TalentsListState>;

/**
 * This function returns an optic that zooms into the criteria of a talent filter
 * with a given uuid
 * @param uuid  Talent filter uuid
 */
export const talentFilterCriteriaWithUuidOfCurrentUser: (
  uuid: string,
) => Optional<
  TalentsListState,
  NonNullable<TalentsListState['tabs'][0]['criteria']>
> = filterCriteriaWithUuidOfCurrentUser<TalentsListState>;

/**
 * This function returns an optic that zooms into the criteria of a talent filter
 * with a given uuid
 * @param uuid  Talent filter uuid
 */
export const talentFilterSortWithUuidOfCurrentUser =
  filterSortWithUuidOfCurrentUser<TalentsListState>;

/**
 * This function returns an optic that zooms into the name of a talent filter
 * with a given uuid
 * @param uuid  Talent filter uuid
 */
export const talentFilterNameWithUuidOfCurrentUser: (
  uuid: string,
) => Optional<TalentsListState, string> =
  filterNameWithUuidOfCurrentUser<TalentsListState>;

/**
 * This function returns an optic that zooms into the pinning state of a talent filter
 * with a given uuid
 * @param uuid  Talent filter uuid
 */
export const talentFilterIsPinnedWithUuidOfCurrentUser: (
  uuid: string,
) => Optional<TalentsListState, boolean> =
  filterIsPinnedWithUuidOfCurrentUser<TalentsListState>;

/**
 * Optic on table configurations of given tabId
 *
 * @param uuid              Talent filter uuid
 */
export const talentTableConfigurationForTabId: (
  uuid: string,
) => Optional<
  TalentsListState,
  ReadonlyArray<TalentsListStateColumnConfiguration>
> = tableConfigurationForTabId<TalentsListState>;

/**
 * Replaces the criteria of the talent filter with the given uuid
 * @param talentsListState  Old talent list state
 * @param uuid              Talent filter uuid
 * @param criteria          New criteria
 */
export const replacePermanentTalentFilterCriteriaOfCurrentUser: (
  talentsListState: TalentsListState,
  uuid: string,
  criteria: TalentCriteriaItemFragment[],
) => TalentsListState =
  replacePermanentFilterCriteriaOfCurrentUser<TalentsListState>;

/**
 * Replaces the sort of the talent filter with the given uuid
 * @param TalentsListState  Old talents list state
 * @param uuid              Talent filter uuid
 * @param sort              New sort
 */
export const replaceTalentSortOfCurrentUser: (
  TalentsListState: TalentsListState,
  uuid: string,
  sort: Required<TalentSortInput>,
) => TalentsListState = replaceSortOfCurrentUser<TalentsListState>;

/**
 * Returns a function that adds the given talent filter
 * @param newTalentFilter    New Talent filter
 */
export const addPermanentTalentFilter: (
  newTalentFilter: TalentsListState['tabs'][0],
) => (state: TalentsListState) => TalentsListState =
  addPermanentFilter<TalentsListState>;

/**
 * Returns a function that sets the given talent filters
 * @param newTalentFilters    New Talent filter
 */
export const changeTalentFilters: (
  newTalentFilters: TalentsListState['tabs'],
) => (state: TalentsListState) => TalentsListState =
  changeFilters<TalentsListState>;

/**
 * Changes the talent filter tab name with the given uuid
 * @param talentsListState  Old talents list state
 * @param uuid              Talent filter uuid
 * @param newTabName        New tab name
 */
export const changeTalentFilterTabName: (
  talentsListState: TalentsListState,
  uuid: string,
  newTabName: string,
) => TalentsListState = changeFilterTabName<TalentsListState>;

/**
 * Changes whether the talent filter is pinned or not
 * @param TalentsListState  Old talents list state
 * @param uuid              Talent filter uuid
 * @param isPinned          New pin state
 */
export const setTalentFilterPinnedState: (
  TalentsListState: TalentsListState,
  uuid: string,
  isPinned: boolean,
) => TalentsListState = setFilterPinnedState<TalentsListState>;

/**
 * Optic on column visibility with id within given tab
 */
export const talentTableColumnVisibilityWithIdForTalentTab =
  tableColumnVisibilityWithIdForTab<TalentsListState>;

/**
 * Updates the column widths of the enabled columns. Expects the
 * number of elements of columns to be same as the number of enabled
 * columns
 * @param uuid        The tab uuid
 * @param newWidths   The widths of the new columns
 */
export const updateEnabledTalentColumnWidthsForTabId =
  updateEnabledColumnWidthsForTabId<TalentsListState>;

/**
 * Defines how a default active tab is selected among the list of tabs
 * @param tabs  list of tabs, typically the saved tabs of the user
 */
export const chooseDefaultActiveTalentTab: (
  tabs: TalentsListState['tabs'],
) => TalentsListState['tabs'][0] = chooseDefaultActiveTab<TalentsListState>;

/**
 * Sets a valid active tab id if the current tab id is not part of the tabs.
 * @param state
 */
export const setValidActiveTalentTab: (
  state: TalentsListState,
) => TalentsListState = setValidActiveTab<TalentsListState>;
