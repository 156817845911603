import { createReducer, on } from '@ngrx/store';
import {
  talentListTabableActions,
  TalentsApiActions,
  TalentsListActions,
} from './talents-list.actions';
import {
  chooseDefaultActiveTalentTab,
  tableConfigurationColumnsOfTalentFilterLens,
} from './talents-list.optics';
import { CurrentUserApiActions } from '../current-user/current-user.actions';
import { Option, pipe } from 'effect';
import { Injectable } from '@angular/core';
import { UserService } from '../../models/shared/user/user.service';
import { lastValueFrom } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { filterIsNotEmpty } from '../../shared/helpers/functions/filterIsNotEmpty';
import * as Optic from '@fp-ts/optic';
import { setDefaultElementsInPosition } from '../../shared/helpers/functions/setDefaultElementsInPosition';
import { ActivatedRoute } from '@angular/router';
import {
  initialTalentTableConfiguration,
  TALENTS_DEFAULT_CRITERIA,
} from '../../pages/talents/talents-table/talents-table.settings';
import { TalentsListState } from './talents-list-state.types';
import {
  clearFilterHistory,
  replaceSortOfCurrentUser,
  tabsLens,
} from '../advanced-data-list/advanced-data-list.optics';
import { tabReducers } from '../tab-management/reducer';

export const PAGE_SIZE = 1000;

export const regularReducer = (initialState: TalentsListState) => {
  return createReducer(
    initialState,
    ...tabReducers<TalentsListState>(talentListTabableActions),
    on(
      TalentsApiActions.loadingTalentIdsSucceeded,
      (_state, { talentIds, totalCount }): TalentsListState => ({
        ..._state,
        itemIds: talentIds,
        isLoadingItems: false,
        totalItemsCount: Option.some(totalCount),
      }),
    ),
    on(
      TalentsApiActions.loadingTalentsStarted,
      (_state): TalentsListState => ({
        ..._state,
        isLoadingItems: true,
      }),
    ),
    on(
      TalentsApiActions.loadingTalentsErrored,
      (_state): TalentsListState => ({
        ..._state,
        isLoadingItems: false,
      }),
    ),
    on(
      TalentsListActions.temporaryQueryChanged,
      (_state, { searchChips }): TalentsListState => ({
        ..._state,
        temporaryQueryChips: searchChips,
        displayItemsLimit: PAGE_SIZE,
      }),
    ),
    on(
      TalentsListActions.loadMoreTalentsClicked,
      (_state): TalentsListState => ({
        ..._state,
        isLoadingItems: true,
        displayItemsLimit: _state.displayItemsLimit + 10000,
      }),
    ),
    on(
      TalentsApiActions.loadingPinnedTabCountSucceeded,
      (_state, { tabId, count }): TalentsListState => ({
        ..._state,
        ...{
          tabTotalCounts: {
            ..._state.tabTotalCounts,
            [tabId]: count,
          },
        },
      }),
    ),
    on(
      TalentsApiActions.loadingSearchOptionsSucceeded,
      (_state, { searchOptions }): TalentsListState => ({
        ..._state,
        ...{ searchOptions },
      }),
    ),
    on(TalentsApiActions.talentSearchHistoryCleared, clearFilterHistory()),
    on(
      CurrentUserApiActions.userLoadedSuccess,
      (_state, userLoadedProps): TalentsListState => ({
        ..._state,
        filterHistory: userLoadedProps.talentFilterHistory,
      }),
    ),
    on(
      TalentsApiActions.talentSearchHistoryAddingSucceeded,
      (_state, { updatedFilterHistory }): TalentsListState => ({
        ..._state,
        filterHistory: updatedFilterHistory,
      }),
    ),
    on(
      TalentsApiActions.receivedTalentListItemData,
      (_state, listItem): TalentsListState => ({
        ..._state,
        listItemsById: {
          ..._state.listItemsById,
          [listItem.uuid]: listItem,
        },
      }),
    ),
    on(
      TalentsListActions.searchHistoryOpened,
      (_state): TalentsListState => ({
        ..._state,
        showFilterHistory: true,
      }),
    ),
    on(
      TalentsListActions.searchHistoryClosed,
      (_state): TalentsListState => ({
        ..._state,
        showFilterHistory: false,
      }),
    ),
    on(CurrentUserApiActions.userLoadedSuccess, (_state, userLoadedProps) =>
      Optic.modify(tabsLens<TalentsListState>())((filters) =>
        filters.map(
          Optic.modify(tableConfigurationColumnsOfTalentFilterLens())(
            (columns) =>
              setDefaultElementsInPosition(
                initialTalentTableConfiguration,
                columns,
                (column1, column2) => column1.columnId === column2.columnId,
              ),
          ),
        ),
      )(_state),
    ),
    on(
      talentListTabableActions.sortChanged,
      (_state, { sort, tabId }): TalentsListState => ({
        ...replaceSortOfCurrentUser<TalentsListState>(_state, tabId, sort),
        displayItemsLimit: PAGE_SIZE,
      }),
    ),
  );
};

@Injectable({
  providedIn: 'root',
})
export class InitialTalentsListStateLoaderService {
  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) {}

  private initialState: TalentsListState | undefined;

  bootstrap(): Promise<boolean> {
    const activeTab = Option.fromNullable(
      this.activatedRoute.snapshot.queryParams?.tabId,
    );
    return this.initialState !== undefined
      ? Promise.resolve(true)
      : lastValueFrom(
          this.userService.readyUser$.pipe(
            filter(filterIsNotEmpty),
            map((user): TalentsListState => {
              const userTalentFilters: NonNullable<
                (typeof user)['talentFilters']
              > = user.talentFilters ?? [];
              return {
                isLoadingItems: true,
                itemIds: [],
                listItemsById: {},
                displayItemsLimit: PAGE_SIZE,
                totalItemsCount: Option.none(),
                tabs: userTalentFilters.map((tab) =>
                  pipe(
                    tab,
                    // this ensures that the criteria are always complete
                    (tab) => ({
                      ...tab,
                      criteria: [...TALENTS_DEFAULT_CRITERIA, ...tab.criteria],
                    }),
                    Optic.modify(tableConfigurationColumnsOfTalentFilterLens())(
                      (columns) =>
                        setDefaultElementsInPosition(
                          initialTalentTableConfiguration,
                          columns,
                          (column1, column2) =>
                            column1.columnId === column2.columnId,
                        ),
                    ),
                  ),
                ),
                editActiveTabId: false,
                tabTotalCounts: {},
                searchOptions: [],
                temporaryQueryChips: [],
                filterHistory: user.talentFilterHistory ?? [],
                showFilterHistory: false,
                tabToDeleteId: Option.none(),
                activeTabId: pipe(
                  activeTab,
                  Option.flatMap(
                    Option.liftPredicate((tabId) =>
                      userTalentFilters.some(
                        (tab) => tab.pinned && tab.uuid === tabId,
                      ),
                    ),
                  ),
                  Option.getOrElse(
                    () => chooseDefaultActiveTalentTab(userTalentFilters).uuid,
                  ),
                ),
              };
            }),
            take(1),
            tap((state) => (this.initialState = state)),
            map((_) => true),
          ),
        );
  }

  createReducer() {
    // At this point initialState should not be undefined anymore
    return regularReducer(this.initialState as TalentsListState);
  }
}
