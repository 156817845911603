import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  TalentCriteriaFieldEnum,
  TalentCriteriaItemFragment,
  TalentFilterInput,
  TalentListItemFragment,
} from '../../graphql/generated';
import {
  ModularSearchChip,
  ModularSearchOption,
} from '../../shared/modules/modular-searchbar/types/modular-search-options';

import { TalentsListState } from './talents-list-state.types';
import { tabActions } from '../tab-management/actions';

export const TalentsListActions = createActionGroup({
  source: 'Talents List',
  events: {
    'Temporary Query changed': props<{
      searchChips: ModularSearchChip<TalentCriteriaFieldEnum>[];
    }>(),
    'Criteria Params changed': props<{
      searchChips: ModularSearchChip<TalentCriteriaFieldEnum>[];
    }>(),
    'List Talent Entered View': props<{
      id: string;
    }>(),
    'Talent Received for List': props<{
      talent: TalentListItemFragment;
    }>(),
    'Talents Page entered': emptyProps(),
    'Talents Page left': emptyProps(),
    'Load More Talents Clicked': emptyProps(),
    'Tab initialized': emptyProps(),
    'Search history Opened': emptyProps(),
    'Search history Closed': emptyProps(),
  },
});

export const TalentsApiActions = createActionGroup({
  source: 'Talents API',
  events: {
    'Loading Talent Ids succeeded': props<{
      talentIds: ReadonlyArray<string>;
      totalCount: number;
    }>(),
    'Loading Talents errored': emptyProps(),
    'Loading Talents started': emptyProps(),
    'Received Talent List Item Data': props<TalentListItemFragment>(),
    'Loading pinned tab count succeeded': props<{
      tabId: string;
      count: number;
    }>(),
    'Loading pinned tab count errored': props<{ tabId: string }>(),
    'Loading search options succeeded': props<{
      searchOptions: ModularSearchOption<TalentCriteriaFieldEnum>[];
    }>(),
    'Talent Search History Cleared': emptyProps(),
    'Talent Search History Adding Started': props<{
      filter: TalentFilterInput;
    }>(),
    'Talent Search History Adding Succeeded': props<{
      updatedFilterHistory: ReadonlyArray<
        ReadonlyArray<TalentCriteriaItemFragment>
      >;
    }>(),
    'Talent Search History Adding Errored': props<{
      error: any;
    }>(),
    'Talent Search History Clearing Started': emptyProps(),
    'Talent Search History Clearing Succeeded': emptyProps(),
    'Talent Search History Clearing Errored': props<{
      error: any;
    }>(),
  },
});

export const talentListTabableActions = tabActions<TalentsListState, 'talents'>(
  'talents',
);
