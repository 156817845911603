import { Injectable } from '@angular/core';
import {
  TalentCreateGQL,
  TalentHistoryListGQL,
  TalentIdsSubscribeGQL,
  TalentIdsSubscribeSubscriptionVariables,
  TalentListItemSubscribeGQL,
  TalentsCountGQL,
  TalentsCountQueryVariables,
  TalentsSubscribeGQL,
  TalentsSubscribeSubscriptionVariables,
  TalentSubscribeGQL,
} from '../../../graphql/generated';
import { catchError, map, scan } from 'rxjs/operators';
import { applySubscriptionChangesToItems } from '../../../shared/helpers/functions/applySubscriptionChangesToItems';
import { GraphQLService } from '../graphql.service';
import { rxThrowIfEmpty } from '../../../shared/helpers/functions/rxThrowIfEmpty';

@Injectable({
  providedIn: 'root',
})
export class TalentsService {
  constructor(
    private talentsSubscribeGql: TalentsSubscribeGQL,
    private talentsCountGQL: TalentsCountGQL,
    private graphQLService: GraphQLService,
    private talentCreateGQL: TalentCreateGQL,
    private talentHistoryListGQL: TalentHistoryListGQL,
    private talentSubscribeGQL: TalentSubscribeGQL,
    private talentIdsSubscribeGQL: TalentIdsSubscribeGQL,
    private talentListItemSubscribeGQL: TalentListItemSubscribeGQL,
  ) {}

  subscribeTalents({
    limit,
    sort,
    filter,
  }: Omit<TalentsSubscribeSubscriptionVariables, 'skip'>) {
    return this.talentsSubscribeGql
      .subscribe(
        {
          skip: 0,
          limit,
          sort,
          filter,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map((res) => ({
          items: res.data?.talentsSubscribe?.items || [],
          itemIds: res.data?.talentsSubscribe?.itemIds || [],
          changedItems: res.data?.talentsSubscribe?.changedItems || [],
          removedItems: res.data?.talentsSubscribe?.removedItems || [],
          addedItems: res.data?.talentsSubscribe?.addedItems || [],
          totalCount: res.data?.talentsSubscribe?.totalCount || null,
        })),
        scan((acc, result) => {
          return applySubscriptionChangesToItems(acc, result, '_id');
        }),
        catchError((err) => {
          this.graphQLService.handleError(err);
          throw err;
        }),
      );
  }

  subscribeTalentIds({
    limit,
    sort,
    filter,
  }: Omit<TalentIdsSubscribeSubscriptionVariables, 'skip'>) {
    return this.talentIdsSubscribeGQL
      .subscribe(
        {
          skip: 0,
          limit,
          sort,
          filter,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map((res) => res.data?.talentIdsSubscribe),
        catchError((err) => {
          this.graphQLService.handleError(err);
          throw err;
        }),
      );
  }

  subscribeTalentListItem$(uuid: string) {
    return this.talentListItemSubscribeGQL
      .subscribe({ uuid }, { fetchPolicy: 'no-cache' })
      .pipe(
        map((res) => {
          return res.data?.talentSubscribe;
        }),
        catchError((err) => {
          this.graphQLService.handleError(err);
          return [];
        }),
      );
  }

  getTalentsCount(variables: TalentsCountQueryVariables) {
    return this.talentsCountGQL
      .fetch(variables, { fetchPolicy: 'no-cache' })
      .pipe(
        map((result) => {
          return result.data.talentsQuery.totalCount;
        }),
      );
  }

  getTalentHistory$(uuid: string) {
    return this.talentHistoryListGQL
      .fetch({ talentUuid: uuid }, { fetchPolicy: 'no-cache' })
      .pipe(
        map((res) => {
          return res.data.talentHistoryList;
        }),
      );
  }

  getTalent$(uuid: string) {
    return this.talentSubscribeGQL
      .subscribe({ uuid }, { fetchPolicy: 'no-cache' })
      .pipe(
        map((res) => {
          return res.data?.talentSubscribe;
        }),
        rxThrowIfEmpty(`Talent with uuid ${uuid} not found`),
      );
  }

  createTalent$() {
    return this.talentCreateGQL.mutate().pipe(
      map((res) => {
        return res.data?.talentCreate;
      }),
      rxThrowIfEmpty(`Server failed to create new talent`),
    );
  }
}
